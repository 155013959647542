import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import footerStyles from './styles/Footer.module.css';

const Footer = () => {
  return (
    <div>
      <footer className={footerStyles.footer}>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={4}>
              <h4>Plymouth Shore on the Bay, LLC</h4>
              <p>8010 E. Bayshore Rd</p>
              <p>Lakeside Marblehead, OH 43440</p>
              <p>plymouthshoreonthebay@gmail.com</p>
              <p>41° 30' 23.2308" N</p>
              <p>82° 43' 35.882" W</p>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <a href='/'>
                <h2 className={footerStyles.footerLogo}>
                  <b>
                    <a href='/'>
                      Plymouth Shore <br />
                      <span className={footerStyles.span}>on the Bay</span>
                    </a>
                  </b>
                </h2>
              </a>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <ul className={footerStyles.footerList}>
                <li>
                  <a href='/'>
                    <p className={footerStyles.linkText}>Home</p>
                  </a>
                </li>
                <li>
                  <a href='/events'>
                    <p className={footerStyles.linkText}>Events</p>
                  </a>
                </li>
                <li>
                  <a href='/contact'>
                    <p className={footerStyles.linkText}>Contact</p>
                  </a>
                </li>
                <li>
                  <a href='/information'>
                    <p className={footerStyles.linkText}>Information</p>
                  </a>
                </li>
                <li>
                  <a href='/safety'>
                    <p className={footerStyles.linkText}>Safety</p>
                  </a>
                </li>
                <li>
                  <a href='/forsale'>
                    <p className={footerStyles.linkText}>For Sale</p>
                  </a>
                </li>
                <li>
                  <a href='/login'>
                    <p className={footerStyles.linkText}>Member Login</p>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <div className={footerStyles.copyright}>
          <p>
            Website designed by{' '}
            <a href='https://xlwebtech.com' className={footerStyles.xlLink}>
              XL Web Technologies
            </a>
          </p>
          <p>© 2024 Plymouth Shore on the Bay, All rights reserved</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
