import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styles from './styles/Forsale.module.css';

const ForSale = () => {
	return (
		<div className={styles.background}>
			<Container>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<Row>
					<Col>
						<h1 className={styles.forSaleHeader}>
							<b>For Sale</b>
						</h1>
						<hr className={styles.divider} />
					</Col>
				</Row>
				<Row>
					<Col
						xs={12}
						sm={12}
						md={{ span: 6, offset: 1 }}
						lg={{ span: 6, offset: 3 }}
					>
						<div className=''>
							<img src='../imgs/For-Sale.png' alt='for sale' />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ForSale;
