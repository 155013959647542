import React from 'react';
import styles from './styles/Contact.module.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Map from '../components/Map';

const Contact = () => {
  return (
    <>
      <div className={styles.background}>
        <Container>
          <Row>
            <Col>
              <h1 className={styles.contactHeader}>
                <b>Contact</b>
              </h1>
              <hr className={styles.divider} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Card className={styles.contactCard}>
                <Card.Body className={styles.contactBody}>
                  <h6>Plymouth Shore on the Bay, LLC</h6>
                  <p>8010 E. Bayshore Rd</p>
                  <p>Lakeside Marblehead, OH 43440</p>
                  <p>plymouthshoreonthebay@gmail.com</p>
                  <hr />
                  <h4 className={styles.playFair}>
                    <b>41° 30' 23.2308" N</b>
                  </h4>
                  <h4 className={styles.playFair}>
                    <b>82° 43' 35.882" W</b>
                  </h4>
                  <hr />
                  <p>Plymouth Shore website admin contact</p>
                  <p>
                    <a href='mailto:dweber@excelautomationinc.com'>
                      dan@excelautomationinc.com
                    </a>
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6} className={styles.lighthouseImg}>
              <Card className={styles.contactImg}>
                <Card.Img src='imgs/contact_img.jpg' />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Row>
        <Map className={styles.map} />
      </Row>
    </>
  );
};

export default Contact;
