import React from 'react';
import navStyles from './styles/Navstyles.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as ReactBootStrap from 'react-bootstrap';

export default function Nav() {
  return (
    <div className={navStyles.nav}>
      <ReactBootStrap.Container>
        <ReactBootStrap.Navbar collapseOnSelect expand='lg'>
          <ReactBootStrap.Navbar.Brand href='/' className={navStyles.brand}>
            <ReactBootStrap.Image
              src='./imgs/logo.png'
              alt='plymouth shore logo'
            ></ReactBootStrap.Image>
          </ReactBootStrap.Navbar.Brand>
          <ReactBootStrap.Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <ReactBootStrap.Navbar.Collapse
            className={navStyles.rightAlign}
            id='responsive-navbar-nav'
          >
            <ReactBootStrap.Nav className='ml-auto'>
              <ReactBootStrap.Nav.Link href='/' className={navStyles.links}>
                <h6 className={navStyles.linkText}>Home</h6>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link href='/events'>
                <h6 className={navStyles.linkText}>Events</h6>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link href='/contact'>
                <h6 className={navStyles.linkText}>Contact</h6>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link href='/information'>
                <h6 className={navStyles.linkText}>Information</h6>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link href='/safety'>
                <h6 className={navStyles.linkText}>Safety</h6>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link href='/forsale'>
                <h6 className={navStyles.linkText}>For Sale</h6>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link href='/login'>
                <h6 className={navStyles.linkText} id='loggedOut'>
                  Member Login
                </h6>
              </ReactBootStrap.Nav.Link>
            </ReactBootStrap.Nav>
          </ReactBootStrap.Navbar.Collapse>
        </ReactBootStrap.Navbar>
      </ReactBootStrap.Container>
    </div>
  );
}
