import React from 'react'
import { Image, Container } from 'react-bootstrap';
import styles from './styles/Forsale.module.css';


const Cabin = () => {
  return (
  <Container>
    <br/>
    <br/> 
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    
    <div className={styles.camperGrid}>
      <Image src="imgs/camper_01.jpg" alt='camper' className={styles.camperImgs}></Image>
      <Image src="imgs/camper_02.jpg" alt='camper' className={styles.camperImgs}></Image>
      <Image src="imgs/camper_05.jpg" alt='camper' className={styles.camperImgs}></Image>
      <Image src="imgs/camper_07.jpg" alt='camper' className={styles.camperImgs}></Image>
      <Image src="imgs/camper_11.jpg" alt='camper' className={styles.camperImgs}></Image>
      <Image src="imgs/camper_08.jpg" alt='camper' className={styles.camperImgs}></Image>
      <Image src="imgs/camper_09.jpg" alt='camper' className={styles.camperImgs}></Image>
      <Image src="imgs/camper_10.jpg" alt='camper' className={styles.camperImgs}></Image>
      <Image src="imgs/camper_06.jpg" alt='camper' className={styles.camperImgs}></Image>
      <Image src="imgs/camper_03.jpg" alt='camper' className={styles.camperImgs}></Image>
      <Image src="imgs/camper_04.jpg" alt='camper' className={styles.camperImgs}></Image>
    </div>

  </Container>
    
  )
}

export default Cabin