import React from 'react';
import styles from './styles/Home.module.css';
import { Row, Col, Card, Image, Container } from 'react-bootstrap';
import Map from '../components/Map';

const Home = () => {
  return (
    <>
      <div className={styles.background}></div>
      <Container>
        <Row className={styles.compassContainer}>
          <Col lg={3}>
            <Image
              src='./imgs/compass_symbol.png'
              alt='compass img'
              width='150px'
              className={styles.compass}
            ></Image>
          </Col>
          <Col lg={3}>
            <h3 className={styles.coords}>41° 30' 23.2308" N</h3>
            <h3 className={styles.coords}>82° 43' 35.882" W</h3>
          </Col>
        </Row>
        <div className={styles.largeScreen}>
          <hr />
          <Row>
            <Col lg={6}>
              <Image
                src='./imgs/plymouthshore_flag.jpg'
                className={styles.image}
              ></Image>
            </Col>
            <Col lg={6}>
              <div className={styles.flagCard}>
                <Card className={styles.card}>
                  <Card.Body>
                    <Card.Text>
                      <h3>Welcome to Our Park</h3>
                      <p>
                        Welcome to Plymouth Shore on the Bay, our member-owned
                        resort park and marina located on beautiful Sandusky Bay
                        in Marblehead, OH. Our park is open from mid April
                        through mid October and features a large marina with
                        boat and jet ski docks, a heated pool, and a recreation
                        barn which hosts many fun events through out the season.
                        Golf carts optional!
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col lg={6}>
              <div className={styles.marinaCard}>
                <Card className={styles.card}>
                  <Card.Body>
                    <Card.Text>
                      <h3>Marina with Boat & Jet Ski Docks</h3>
                      <p>
                        Plymouth Shore on the Bay includes a large, well
                        maintained marina within walking distance of every lot
                        in the park. Some docks are golf cart accessible, making
                        loading and unloading coolers and beach gear really
                        simple. The Plymouth Shore marina has floating boat
                        docks up to 30ft, jet boat docks and jet ski docks as
                        well. Play on the bay, relax at the sandbar, or cruise
                        to the Lake Erie Islands!
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col lg={6}>
              <Image src='./imgs/marina.jpg' className={styles.image}></Image>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg={6}>
              <Image src='./imgs/pool.jpg' className={styles.image}></Image>
            </Col>
            <Col lg={6}>
              <div className={styles.marinaCard}>
                <Card className={styles.card}>
                  <Card.Body>
                    <Card.Text>
                      <h3>Heated Pool</h3>
                      <p>
                        Get out of the summer heat and take a dip in our pool.
                        Plymouth Shore members enjoy the heated pool, which is
                        usually open Memorial Day to mid September. It is also
                        the site of a couple pool party’s each year, which are a
                        great time.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className={styles.marinaCard}>
                <Card className={styles.card}>
                  <Card.Body>
                    <Card.Text>
                      <h3>Located on Beautiful Sandusky Bay</h3>
                      <p>
                        Plymouth Shore is located on Sandusky Bay, near most of
                        the Lake Erie Shores & Islands attractions. It’s a quick
                        boat ride to Cedar Point, the Sandusky Bay sandbar,
                        Put-In-Bay, Kelley’s Island, and more. Also located in
                        close proximity to many restaurants, water parks and
                        beaches. Too much to list!
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col lg={6}>
              <Image src='./imgs/overview.jpg' className={styles.image}></Image>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <Row>
            <Col lg={6}>
              <Image src='./imgs/band.jpg' className={styles.bandImage}></Image>
            </Col>
            <Col lg={6}>
              <div className={styles.eventsCard}>
                <Card className={styles.card}>
                  <Card.Body>
                    <Card.Text>
                      <h3>Many Fun Events</h3>
                      <p>
                        Plymouth Shore on the Bay has a member event committee
                        that provides entertainment throughout the season. Some
                        of the events includes bands in the barn, one man band
                        on the break wall, marina party’s, pool party’s,
                        Halloween party’s, scavenger hunts, and lot more. These
                        events help make a very memorable summer on the bay.
                        Click here to view this year’s event schedule.
                      </p>
                      <a href='/events' className={styles.button}>
                        EVENT SCHEDULE
                      </a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <Row>
            <Col lg={6}>
              <div className={styles.marinaCard}>
                <Card className={styles.card}>
                  <Card.Body>
                    <Card.Text>
                      <h3>Golf Carts Welcome</h3>
                      <p>
                        Plymouth Shore on the Bay is a golf cart community.
                        Electric carts are optional, and can be used to get to
                        and from your boat dock, pool, park meetings, and
                        parties. Jump on your cart to visit your friend on the
                        other side of the park. Or simply cruise the park in
                        your cart or head down to the break wall to catch a
                        sunset.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col lg={6}>
              <Image src='./imgs/golf.jpg' className={styles.image}></Image>
            </Col>
          </Row>
        </div>
      </Container>
      {/* start small screen */}
      <div className={styles.smallScreen}>
        <section className={styles.section}>
          <Container>
            <Row>
              <Col lg={6}>
                <div className={styles.flagCard}>
                  <Card className={styles.card}>
                    <Card.Body>
                      <Card.Text>
                        <h3>
                          <b>Welcome to Our Park</b>
                        </h3>
                        <p>
                          Welcome to Plymouth Shore on the Bay, our member-owned
                          resort park and marina located on beautiful Sandusky
                          Bay in Marblehead, OH. Our park is open from mid April
                          through mid October and features a large marina with
                          boat and jet ski docks, a heated pool, and a
                          recreation barn which hosts many fun events through
                          out the season. Golf carts optional!
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col lg={6}>
                <Image
                  src='./imgs/plymouthshore_flag.jpg'
                  className={styles.image}
                ></Image>
              </Col>
            </Row>
            <hr className={styles.mobileSpacer} />
            <Row>
              <Col lg={6}>
                <div className={styles.marinaCard}>
                  <Card className={styles.card}>
                    <Card.Body>
                      <Card.Text>
                        <h3>Marina with Boat & Jet Ski Docks</h3>
                        <p>
                          Plymouth Shore on the Bay includes a large, well
                          maintained marina within walking distance of every lot
                          in the park. Some docks are golf cart accessible,
                          making loading and unloading coolers and beach gear
                          really simple. The Plymouth Shore marina has floating
                          boat docks up to 30ft, jet boat docks and jet ski
                          docks as well. Play on the bay, relax at the sandbar,
                          or cruise to the Lake Erie Islands!
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col lg={6}>
                <Image src='./imgs/marina.jpg' className={styles.image}></Image>
              </Col>
            </Row>
            <hr className={styles.mobileSpacer} />
            <Row>
              <Col lg={6}>
                <div className={styles.marinaCard}>
                  <Card className={styles.card}>
                    <Card.Body>
                      <Card.Text>
                        <h3>Heated Pool</h3>
                        <p>
                          Get out of the summer heat and take a dip in our pool.
                          Plymouth Shore members enjoy the heated pool, which is
                          usually open Memorial Day to mid September. It is also
                          the site of a couple pool party’s each year, which are
                          a great time.
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col lg={6}>
                <Image src='./imgs/pool.jpg' className={styles.image}></Image>
              </Col>
            </Row>
            <hr className={styles.mobileSpacer} />
            <Row>
              <Col lg={6}>
                <div className={styles.marinaCard}>
                  <Card className={styles.card}>
                    <Card.Body>
                      <Card.Text>
                        <h3>Located on Beautiful Sandusky Bay</h3>
                        <p>
                          Plymouth Shore is located on Sandusky Bay, near most
                          of the Lake Erie Shores & Islands attractions. It’s a
                          quick boat ride to Cedar Point, the Sandusky Bay
                          sandbar, Put-In-Bay, Kelley’s Island, and more. Also
                          located in close proximity to many restaurants, water
                          parks and beaches. Too much to list!
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col lg={6}>
                <Image
                  src='./imgs/overview.jpg'
                  className={styles.overviewImage}
                ></Image>
              </Col>
            </Row>
            <hr className={styles.mobileSpacer} />
            <br />
            <Row>
              <Col lg={6}>
                <div className={styles.eventsCard}>
                  <Card className={styles.card}>
                    <Card.Body>
                      <Card.Text>
                        <h3>Many Fun Events</h3>
                        <p>
                          Plymouth Shore on the Bay has a member event committee
                          that provides entertainment throughout the season.
                          Some of the events includes bands in the barn, one man
                          band on the break wall, marina party’s, pool party’s,
                          Halloween party’s, scavenger hunts, and lot more.
                          These events help make a very memorable summer on the
                          bay. Click here to view this year’s event schedule.
                        </p>
                        <a href='/events' className={styles.button}>
                          EVENT SCHEDULE
                        </a>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col lg={6}>
                <Image
                  src='./imgs/band.jpg'
                  className={styles.bandImage}
                ></Image>
              </Col>
            </Row>
            <hr className={styles.mobileSpacer} />
            <Row>
              <Col lg={6}>
                <div className={styles.marinaCard}>
                  <Card className={styles.card}>
                    <Card.Body>
                      <Card.Text>
                        <h3>Golf Carts Welcome</h3>
                        <p>
                          Plymouth Shore on the Bay is a golf cart community.
                          Electric carts are optional, and can be used to get to
                          and from your boat dock, pool, park meetings, and
                          parties. Jump on your cart to visit your friend on the
                          other side of the park. Or simply cruise the park in
                          your cart or head down to the break wall to catch a
                          sunset.
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col lg={6}>
                <Image
                  src='./imgs/golf.jpg'
                  className={styles.golfCartImage}
                ></Image>
              </Col>
            </Row>
            <br />
          </Container>
        </section>
      </div>
      {/* End small screen */}
      <Container>
        <h1 className={styles.parkPicsTitle}>Check out our park pics</h1>
        <hr />
        <Row>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic1.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic2.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic3.png'
              className={styles.parkPics}
            ></Image>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic4.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic5.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic6.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic7.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic8.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic9.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic10.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic11.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic12.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic13.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic14.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
          <Col md={12} lg={4}>
            <Image
              src='./imgs/parkpic15.jpg'
              className={styles.parkPics}
            ></Image>
          </Col>
        </Row>
      </Container>

      <br />
      <Row>
        <Map />
      </Row>
    </>
  );
};

export default Home;
