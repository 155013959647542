import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import styles from './styles/Breakwall.module.css';

export default function BreakWall() {
  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={4} lg={4}>
            <Card className={styles.img}>
              <Card.Img src='./imgs/break_wall2.jpg'></Card.Img>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <Card className={styles.img}>
              <Card.Img src='./imgs/break_wall3.jpg'></Card.Img>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <Card className={styles.img}>
              <Card.Img src='./imgs/break_wall4.jpg'></Card.Img>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={12} md={4} lg={4}>
            <Card className={styles.img}>
              <Card.Img src='./imgs/break_wall5.jpg'></Card.Img>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <Card className={styles.img}>
              <Card.Img src='./imgs/break_wall6.jpg'></Card.Img>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <Card className={styles.img}>
              <Card.Img src='./imgs/break_wall7.jpg'></Card.Img>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={12} md={4} lg={4}>
            <Card className={styles.img}>
              <Card.Img src='./imgs/break_wall8.jpg'></Card.Img>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <a href='/loggedin' className='btn btn-primary'>
              Back
            </a>
          </Col>
        </Row>
      </Container>
      <br />
    </div>
  );
}
