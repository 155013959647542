import React, { useRef, useState } from 'react';
import styles from './styles/Login.module.css';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history.push('/loggedin');
    } catch {
      setError('Incorrect Password');
    }
    setLoading(false);
  }

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <Card styles={styles.loginCard}>
              <Card.Body>
                <h3 className={styles.loginHeader}>
                  Plymouth Shore on the Bay <br />
                  Member Login
                </h3>
                {error && <Alert variant='danger'>{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                  <Form.Group id='email'>
                    <Form.Control
                      type='email'
                      value='plymouthshore@gmail.com'
                      ref={emailRef}
                      className={styles.emailInputBox}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type='password'
                      ref={passwordRef}
                      placeholder='Enter password'
                    ></Form.Control>
                  </Form.Group>
                  <br />
                  <Form.Group>
                    <Form.Row>
                      <Button
                        disabled={loading}
                        variant='primary'
                        type='submit'
                        className={styles.loginBtn}
                      >
                        Login
                      </Button>
                    </Form.Row>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
