import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 41.507593693473396,
  lng: -82.77980782602435,
};

export default function Map() {
  return (
    <LoadScript googleMapsApiKey='AIzaSyBoAMFdCXujw2QV_EFjjykilWEco7LKomY'>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
        {/* Child components, such as markers, info windows, etc. */}
        <></>
      </GoogleMap>
    </LoadScript>
  );
}
