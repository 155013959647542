import React from 'react';
import styles from './styles/Safety.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import HoverImage from './HoverImage';

const Safety = () => {
  return (
    <div className={styles.background}>
      <Container>
        <Row>
          <Col>
            <h1 className={styles.safetyHeader}>
              <b>Safety</b>
            </h1>
            <hr className={styles.divider} />
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Row>
              <div id='safetyNav' className={styles.safetyNav}>
                <Col md={12} lg={12}>
                  <div className={styles.safetyMenu}>
                    <ul id='' className='safety-menu'>
                      <li>
                        <a href='https://plymouthshoreonthebay.com/safety#roads'>
                          ROADS
                        </a>
                      </li>
                      <li>
                        <a href='https://plymouthshoreonthebay.com/safety#golfCarts'>
                          GOLF CARTS
                        </a>
                      </li>
                      <li>
                        <a href='https://plymouthshoreonthebay.com/safety#pools'>
                          SWIMMING POOL
                        </a>
                      </li>
                      <li>
                        <a href='https://plymouthshoreonthebay.com/safety#defib'>
                          DEFIBRILLATOR
                        </a>
                      </li>
                      <li>
                        <a href='http://plymouthshoreonthebay.com/safety#other'>
                          OTHER
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 6, offset: 2 }}>
            <ul className={styles.safetyMobileLinks}>
              <li>
                <a href='https://plymouthshoreonthebay.com/safety#roads'>
                  ROADS
                </a>
              </li>
              <li>
                <a href='https://plymouthshoreonthebay.com/safety#golfCarts'>
                  GOLF CARTS
                </a>
              </li>
              <li>
                <a href='https://plymouthshoreonthebay.com/safety#pools'>
                  SWIMMING POOL
                </a>
              </li>
              <li>
                <a href='https://plymouthshoreonthebay.com/safety#defib'>
                  DEFIBRILLATOR
                </a>
              </li>
              <li>
                <a href='https://plymouthshoreonthebay.com/safety#other'>
                  OTHER
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <div className='col s12 m12 l12'>
            <div className='card mt-4'>
              <div className='card-body'>
                <div className='card-title'>
                  <h2 className={styles.cardHeader}>
                    <b>EMERGENCY NUMBERS</b>
                  </h2>
                </div>
                <ul className={styles.safetyList}>
                  <li>
                    All Emergencies: <a href='tel:911'>911</a>
                  </li>
                  <li>
                    Police Station:{' '}
                    <a href='tel:(419) 732-2549'>(419) 732-2549</a>
                  </li>
                  <li>
                    Marblehead <a href='tel:(419) 798-4450'>(419) 798-4450</a>
                  </li>
                  <li>
                    Fire Department: Lakeside{' '}
                    <a href='tel:(419) 798-5219'>(419) 798-5219 </a>
                    and <a href='tel:(419) 734-97004'>(419) 734-97004</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className='col s12 m12 l12'>
            <div className='card mt-4'>
              <div className='card-body'>
                <h2 id='defib' className={styles.cardHeader}>
                  DEFIBRILLATOR
                </h2>
                <ul className={styles.safetyList}>
                  <li className={styles.subHeader}>LOCATION</li>
                  <p>Manager's office - Front porch</p>
                </ul>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className='col s12 m12 l12'>
            <div className='card mt-4'>
              <div className='card-body'>
                <h2 id='roads' className={styles.cardHeader}>
                  ROADS
                </h2>
                <ul className={styles.safetyList}>
                  <li className={styles.subHeader}>SPEED LIMITS</li>
                  <p>Speed limit for all motor vehicles is 5mph</p>
                  <li className={styles.subHeader}>ONE WAY ROADS</li>
                  <p>Below is a map of the One-Way Roads</p>
                </ul>
              </div>
            </div>
            <HoverImage />
          </div>
        </Row>
        <Row>
          <div className='col s12 m12 l12'>
            <div className='card mt-4'>
              <div className='card-body'>
                <h2 id='golfCarts' className={styles.cardHeader}>
                  GOLF CARTS
                </h2>
                <ul className={styles.safetyList}>
                  <li className={styles.subHeader}>SPEED LIMIT</li>
                  <p>Speed limit for golf carts is 5mph</p>
                  <li className={styles.subHeader}>RULES</li>
                  <ul className={styles.subList}>
                    <li style={{ color: 'black' }}>
                      Golf Carts can only be operated by an individual with a
                      valid driver’s license
                    </li>
                    <li style={{ color: 'black' }}>
                      Owners will register their golf carts, sign a form
                      acknowledging the rule change, and be given stickers to
                      place on both sides of the golf cart.
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className='col s12 m12 l12'>
            <div className='card mt-4'>
              <div className='card-body'>
                <h2 className={styles.cardHeader}>BIKES</h2>
                <ul className={styles.safetyList}>
                  <li>No biking is allowed on the break wall</li>
                </ul>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className='col s12 m12 l12'>
            <div className='card mt-4'>
              <div className='card-body'>
                <h2 id='pools' className={styles.cardHeader}>
                  SWIMMING POOL
                </h2>
                <ul className={styles.safetyList}>
                  <li className={styles.subHeader}>RULES</li>
                  <ul className={styles.subList}>
                    <li style={{ color: 'black' }}>
                      Swim at your own risk, no lifeguard
                    </li>
                    <li style={{ color: 'black' }}>No diving</li>
                    <li style={{ color: 'black' }}>No glass</li>
                    <li style={{ color: 'black' }}>
                      Floats are allowed when the pool is not crowded
                    </li>
                    <li style={{ color: 'black' }}>Never swim alone</li>
                    <li style={{ color: 'black' }}>
                      Children, and non-swimmers, may not swim without adult
                      supervision.
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className='col s12 m12 l12'>
            <div className='card mt-4'>
              <div className='card-body'>
                <h2 id='other' className={styles.cardHeader}>
                  MARINA
                </h2>
                <ul className={styles.safetyList}>
                  <li>
                    No one under 12 years of age is permitted on or around the
                    docks or break wall unless they are accompanied by an adult.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className='col s12 m12 l12'>
            <div className='card mt-4'>
              <div className='card-body'>
                <h2 className={styles.cardHeader}>CAMPFIRES</h2>
                <ul className={styles.safetyList}>
                  <li>Do not leave a fire unattended.</li>
                  <li>No bonfires are allowed.</li>
                  <li>No fires are permitted on or around the dock area.</li>
                </ul>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className='col s12 m12 l12'>
            <div className='card mt-4'>
              <div className='card-body'>
                <h2 className={styles.cardHeader}>PETS</h2>
                <ul className={styles.safetyList}>
                  <li>Do not leave pets unattended.</li>
                  <li>Pets must be kept on leash.</li>
                  <li>Clean up after your pet.</li>
                </ul>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className='col s12 m12 l12'>
            <div className='card mt-4'>
              <div className='card-body'>
                <h2 className={styles.cardHeader}>FIREWORKS</h2>
                <ul className={styles.safetyList}>
                  <li>No Fireworks are Allowed</li>
                </ul>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Safety;
