import Nav from '../src/components/Nav';
import React from 'react';
import Home from '../src/components/Home';
import Footer from '../src/components/Footer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from '../src/components/Login';
import AuthProvider from './contexts/AuthContext';
import PrivateRoute from '../src/components/PrivateRoute';
import LoggedIn from '../src/components/Loggedin';
import Events from '../src/components/Events';
import Contact from '../src/components/Contact';
import Info from './components/Information';
import Safety from '../src/components/Safety';
import ForSale from '../src/components/ForSale';
import Breakwall from '../src/components/BreakWall';
import PoolTables from '../src/components/PoolTables';
import Cabin from '../src/components/Cabin'

function App() {
  return (
    <>
      <Router>
        <Nav />
        <AuthProvider>
          <Switch>
            <PrivateRoute exact path='/loggedin' component={LoggedIn} />
            <Route path='/' component={Home} exact />
            <Route path='/login' component={Login} />
            <Route path='/events' component={Events} />
            <Route path='/contact' component={Contact} />
            <Route path='/information' component={Info} />
            <Route path='/safety' component={Safety} />
            <Route path='/forsale' component={ForSale} />
            <Route path='/breakwall' component={Breakwall} />
            <Route path='/pooltables' component={PoolTables} />
            <Route path='/cabin' component={Cabin} />
          </Switch>
        </AuthProvider>
        <Footer />
      </Router>
    </>
  );
}

export default App;
