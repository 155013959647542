import React, { useEffect } from 'react';
import MapImage from '../images/safety_map.png';
import { Image } from 'react-bootstrap';
import styles from './styles/Safety.module.css';

export default function HoverImage() {
  useEffect(() => {
    let image = document.getElementById('mapImage');
    image.addEventListener('click', (e) => {
      image.style.width = '80%';
    });

    image.addEventListener('mouseleave', (e) => {
      image.style.width = '50%';
    });
  });

  return (
    <>
      <Image
        src={MapImage}
        className={styles.safetyMap}
        id='mapImage'
        width='500'
        height='auto'
      ></Image>
    </>
  );
}
