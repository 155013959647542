import firebase from 'firebase/app';
import 'firebase/auth';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyBoAMFdCXujw2QV_EFjjykilWEco7LKomY',
  databaseURL: 'https://plymouthshoreonthebay-default-rtdb.firebaseio.com',
  authDomain: 'plymouthshoreonthebay.firebaseapp.com',
  projectId: 'plymouthshoreonthebay',
  storageBucket: 'plymouthshoreonthebay.appspot.com',
  messagingSenderId: '854694976441',
  appId: '1:854694976441:web:9017828ac53dd37dccb468',
});

export const auth = app.auth();
export default app;
