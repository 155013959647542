import React, { Fragment } from 'react';
import styles from './styles/Info.module.css';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import Map from './Map';
import image from '../images/info_map_new.png';

const Information = () => {
  if (typeof window === 'object') {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (!d.getElementById(id)) {
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://weatherwidget.io/js/widget.min.js';
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, 'script', 'weatherwidget-io-js');
  }

  return (
    <>
      <div className={styles.background}>
        <Container>
          <Row>
            <Col>
              <h1 className={styles.infoHeader}>
                <b>Information</b>
              </h1>
              <hr className={styles.divider} />
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 6, offset: 3 }}
            >
              <Image
                className={styles.map}
                src={image}
                rounded
                alt='Map'
              ></Image>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 6, offset: 3 }}
            >
              <Card className={styles.overflowCard}>
                <Card.Body>
                  <h3 className={styles.overFlowTitle}>Overflow Parking</h3>
                  <p>
                    Overflow parking is available across the street. Please park
                    responsibly and within the allowed area. No boat trailers
                    permitted unless personal permission is granted by the
                    property owner. Parking is at your own risk and the property
                    owner or Plymouth Shore on the Bay, LLC is not responsible
                    for damage or theft to vehicles or vehicle contents or
                    injury to persons. Park directly north of East Bayshore
                    Road.
                    <br />
                    <br />
                    <b>
                      Stay away from low areas by the drainage swale and the
                      firepit
                    </b>
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Image
                className={styles.parkingImg}
                src='./imgs/overflow_parking.png'
              ></Image>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Image
                className={styles.parkingImg}
                src='./imgs/parking_june2021.jpg'
              ></Image>
            </Col>
          </Row>
          <br />
          <Row>
            <Col
              sm={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
            >
              <Card className={styles.conditionsCard}>
                <Card.Title>
                  <h2 className={styles.weatherTitle}>
                    Lake Erie Weather Conditions & <br></br>Ohio Boating Laws
                  </h2>
                  <hr className={styles.spacer} />
                </Card.Title>
                <Card.Body>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <a href='https://www.ndbc.noaa.gov/data/Forecasts/FZUS61.KCLE.html'>
                        <Image
                          className={styles.icons}
                          src='./imgs/wave.png'
                        ></Image>
                        <h5 className={styles.iconText}>
                          Lake Erie Marine Forecast
                        </h5>
                      </a>
                      <a href='https://www.shoresandislands.com/webcams/'>
                        <Image
                          className={styles.icons}
                          src='./imgs/webcam.jpg'
                        ></Image>
                        <h5 className={styles.iconText}>
                          Lake Erie Shores and Islands Webcams
                        </h5>
                      </a>
                    </Col>
                  </Row>

                  <a href='https://fox8.com/news/entertainment/jet-express-live-lake-view-cam/'>
                    <Image
                      className={styles.bottomWebCam}
                      src='./imgs/webcam.jpg'
                    ></Image>
                    <h5 className={styles.iconText}>
                      Jet Express Put-In-Bay Webcam
                    </h5>
                  </a>
                  <a href='https://glbuoys.glos.us'>
                    <Image
                      className={styles.icons}
                      src='./imgs/buoy.jpg'
                    ></Image>
                    <h5 className={styles.iconText}>Lake Erie Buoy Reports</h5>
                  </a>
                  <a href='https://ohiodnr.gov/wps/portal/gov/odnr/rules-and-regulations/rules-and-regulations-by-division/state-parks-and-watercraft/boating-rules/ohio-operating-laws'>
                    <Image
                      className={styles.icons}
                      src='./imgs/boaticon.png'
                    ></Image>
                    <h5 className={styles.iconText}>Ohio Boating Laws</h5>
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 6, offset: 3 }}
            >
              <h3 className={styles.weatherAPITitle}>
                Current Weather Forecast
              </h3>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <Fragment>
                <a
                  class='weatherwidget-io'
                  href='https://forecast7.com/en/41d54n82d74/lakeside-marblehead/?unit=us'
                  data-label_1='LAKESIDE MARBLEHEAD'
                  data-label_2='WEATHER'
                  data-theme='original'
                >
                  LAKESIDE MARBLEHEAD WEATHER
                </a>
              </Fragment>
            </Col>
          </Row>
        </Container>
      </div>
      <Row className={styles.weatherForecast}>
        <Map />
      </Row>
    </>
  );
};

export default Information;
