import React from 'react';
import styles from './styles/Events.module.css';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Events = () => {
  return (
    <div className={styles.background}>
      <Container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col>
            <h1 className={styles.eventsHeader}>
              <b>Events</b>
            </h1>
            <hr className={styles.divider} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.eventScheduleWrapper}>
              <Card className={styles.eventSchedule}>
                <Card.Img
                  variant='top'
                  className={styles.eventsImg}
                  src='imgs/event_schedule.png'
                  alt='Event Schedule'
                />
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Events;
