import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styles from './styles/PoolTable.module.css';

export default function PoolTables() {
  return (
    <Container>
      <Row>
        <Col sm={12} md={4} lg={4}>
          <Card className={styles.card}>
            <Card.Img src='./imgs/pool_img1.jpg'></Card.Img>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4}>
          <Card className={styles.card}>
            <Card.Img src='./imgs/pool_img2.jpg'></Card.Img>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4}>
          <Card className={styles.card}>
            <Card.Img src='./imgs/pool_img3.jpg'></Card.Img>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} lg={4}>
          <Card className={styles.card}>
            <Card.Img src='./imgs/pool_img1.jpg'></Card.Img>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4}>
          <Card className={styles.card}>
            <Card.Img src='./imgs/pool_img2.jpg'></Card.Img>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4}>
          <a href='/loggedin' className='btn btn-primary'>
            Back
          </a>
        </Col>
      </Row>
    </Container>
  );
}
